<template>
  <section id="monitoring-area">
  <div class="vx-con--tabs">
    <div class="vx-con-tabs-nav flex sm:justify-between sm:items-center mb-3">
      <ul class="flex">
        <li class="relative">
            <vs-button
              @click="$router.push('/monitoring').catch(() => [])"
              type="line"
              icon-pack="feather"
              icon="icon-list"
              :class="{ 'vs-button-line--active': $route.name === 'monitorings' }"
            >
              {{ !isSmallerScreen ? 'List' : '' }}
            </vs-button>
        </li>
        <li class="relative">
          <vs-button
            @click="$router.push('/monitoring/domains').catch(() => ([]))"
            type="line"
            icon-pack="feather"
            icon="icon-link-2"
            :class="{ 'vs-button-line--active': $route.path === '/monitoring/domains' }">
            By domain
          </vs-button>
        </li>
        <li class="relative">
          <vs-button
            @click="$router.push('/monitoring/tests').catch(() => ([]))"
            type="line"
            icon-pack="feather"
            icon="icon-code"
            :class="{ 'vs-button-line--active': $route.path === '/monitoring/tests' }">
            Test Suites
          </vs-button>
        </li>
      </ul>
    </div>
    <div class="vx-con--tabs-block">
      <router-view />
    </div>
  </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
  components: {},
  data() {
    return {

    }
  },
  computed: {
    ...mapState('monitoring', ['list', 'testItem']),
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768;
    },
    list() {
      if (Array.isArray(this.data)) return this.data;
      return this.data.items || [];
    },
    monitorTitle() {
      if (this.isMounted) return this.item.name;
      return null;
    },
    testTitle() {
      if (!this.isMounted || !this.testItem) return null
      if (this.isMounted) return this.testItem.name;
      return null;
    },
  },
  methods: {

  },
  beforeCreate() {},
  created() {},
  mounted() { }
};
</script>

<style lang="scss">
.vs-collapse.margin .vs-collapse-item:first-child {
  margin-top: 0 !important;
}
</style>
